.PopupBox-root {
    position: relative;
    display: inline-block;
  }
  
  .PopupBox-popup {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -10px);
    border: 1px solid white;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    z-index: 1000;
    opacity: 0;
    width: 0px;
    transition: opacity, width 0.3s ease-in-out;
    animation: fadeIn 0.5s forwards;
  }
  
  @keyframes fadeIn {
    to {opacity: 1;}
    to {width: 320px;}
  }
  