body {
  margin: 0;
  font-family:  'Bergen',
    sans-serif;
  font-size: 25pt;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: "black";
}

@font-face {
  font-family: 'Bergen';
  src: url('./fonts/BergenMono-Regular.woff') format('woff');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  overflow:   scroll;
}
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent; /* make scrollbar transparent */
}